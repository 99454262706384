<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="planName"
                    label="计划名称">
                    <el-input
                        v-model="queryFormModel.planName"
                        placeholder="请输入计划名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="planStatus"
                    label="计划状态">
                    <dictionaries-picker
                        type="assessPlanStatus"
                        v-model="queryFormModel.planStatus"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"> 查询 </el-button>
                    <el-button
                        type="success"
                        @click="onReset"> 重置 </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying">
            <!-- 操作 -->
            <div class="ma-b">
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="$router.push('/am/assessPlan/healthyPlan/edit')"
                    >
                        新增
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                @selection-change="onTableSelectionChange"
            >
                <el-table-column
                    prop="planName"
                    label="计划名称"
                    min-width="100" />
                <el-table-column
                    label="开始时间"
                    width="135">
                    <template slot-scope="scope">
                        {{ scope.row.beginTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="结束时间"
                    width="135">
                    <template slot-scope="scope">
                        {{ scope.row.endTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="scaleName"
                    label="量表名称"
                    min-width="100" />
                <el-table-column
                    prop="planStatusName"
                    label="计划状态"
                    min-width="100"
                />
                <el-table-column
                    label="创建时间"
                    width="135">
                    <template slot-scope="scope">
                        {{ scope.row.insertTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="修改时间"
                    width="135">
                    <template slot-scope="scope">
                        {{ scope.row.updateTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="110">
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="
                                $router.push('/am/assessPlan/healthyPlan/edit/' + scope.row.id)
                            "
                        >
                            {{ scope.row.planStatus === 10 ? '编辑' : '查看' }}
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableDelete(scope.row.id)"
                            v-if="scope.row.planStatus === 10"
                        >
                            删除
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableCancel(scope.row.id)"
                            v-if="scope.row.planStatus === 100"
                        >
                            取消发布
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery" />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'AssessPlan',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                planName: '',
                planStatus: '',
                planType: '1',
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Am.AssessPlan.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        onTableDelete(ids) {
            this.$confirm('是否确定要删除！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Am.AssessPlan.delete({ ids }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
        onTableCancel(ids) {
            this.$confirm('是否确定要取消发布计划！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Am.AssessPlan.cancel({ ids }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
    },
};
</script>

<style lang="scss">
</style>
